import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/mdx-page';
export const _frontmatter = {
  "title": "Help",
  "subtitle": "Find answers to common questions"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`What will the postcards look like?`}</h2>
    <p>{`You'll get to see a preview of the postcard before sending it out, and we use high-quality stock cards with a gloss finish.`}</p>
    <h2>{`How long does it take to receive postcards?`}</h2>
    <p>{`We can guarantee that your postcard will be printed and shipped within 2 business days. General delivery window:`}</p>
    <ul>
      <li parentName="ul">{`United Kingdom: 1-4 business days`}</li>
      <li parentName="ul">{`Everywhere else: 4-10 business days`}</li>
    </ul>
    <h2>{`My recipient still hasn’t received their card. Where is it?`}</h2>
    <p>{`The cards are sent to the postal service within 2 business days, but we can't control what happens once the postcard is in the hands of the postal service. If it has not been delivered within 3 weeks, please contact us by email.`}</p>
    <h2>{`Where are the postcards sent from?`}</h2>
    <p>{`The postcards are printed and shipped from the United Kingdom.`}</p>
    <h2>{`Is it possible to send cards all over the world?`}</h2>
    <p>{`Yes, you can send the postcards anywhere, at any time - at no additional cost!`}</p>
    <h2>{`Can I edit a card that has been sent?`}</h2>
    <p>{`Once sent, the front of a postcard can no longer be edited. However, you can still change the message (if you'd like to send another one to someone else), and you can change the stories linked to that postcard.`}</p>
    <h2>{`Can I use smileys or emoticons on my postcard?`}</h2>
    <p>{`Yes! You can add smileys, emoticons, and illustrations wherever you want on the card. It's completely customizable.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      